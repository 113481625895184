    /* .nav-it {
        width: 50%;
    } */

    .overview {
        height: 100%;
    }

    .csh-crd {
        height: 50px;
    }


    @media only screen and (max-width:760px) {
        .nav-it {
            width: 50%;
        }
    }




    .top-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #00BAF0;
        background: #5F2F1B;
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color: #FFF;
        height: 50px;
        padding: 1em;
      }
      
      .menu {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      
      .menu > li {
        margin: 0 1rem;
        overflow: hidden;
      }
      
      .menu-button-container {
        display: none;
        height: 100%;
        width: 30px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      #menu-toggle {
        display: none;
      }
      
      .menu-button,
      .menu-button::before,
      .menu-button::after {
        display: block;
        background-color: #fff;
        position: absolute;
        height: 4px;
        width: 30px;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 2px;
      }
      
      .menu-button::before {
        content: '';
        margin-top: -8px;
      }
      
      .menu-button::after {
        content: '';
        margin-top: 8px;
      }
      
      #menu-toggle:checked + .menu-button-container .menu-button::before {
        margin-top: 0px;
        transform: rotate(405deg);
      }
      
      #menu-toggle:checked + .menu-button-container .menu-button {
        background: rgba(255, 255, 255, 0);
      }
      
      #menu-toggle:checked + .menu-button-container .menu-button::after {
        margin-top: 0px;
        transform: rotate(-405deg);
      }
      
      @media (max-width: 700px) {
        .menu-button-container {
          display: flex;
        }
        .menu {
          position: absolute;
          top: 15%;
          margin-top: 50px;
          left: 0;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          align-items: center;
          z-index: 10;
        }

        .menu li a{
            width: 100%;
        }

        #menu-toggle ~ .menu li {
          background-color: white;
          height: 0;
          margin: 0;
          padding: 0;
          border: 0;
          transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }
        #menu-toggle:checked ~ .menu li {
          border: 1px solid #333;
          height: 4em;
          padding: 0.5em;
          transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }
        .menu > li {
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 0.5em 0;
          width: 100%;
          color: white;
          background-color: #222;
        }
        .menu > li:not(:last-child) {
          border-bottom: 1px solid #444;
        }
      }