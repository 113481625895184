body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control{
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  border-radius: 10px;
  
}
.head-control{
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.p-text{
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 35px !important;
}

.text-input{
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: 45px;
  padding: 10px 10px 10px 10px;
  background-color: #FFF !important;
}

.text-input-address{
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: 38px;
  /* padding: 10px 10px 10px 10px !important; */
  border-radius: 7px !important;
  background-color: #FFFFFF  !important;
}
.custom-password-input .ant-input {
  background-color: transparent;
}
.card-body {
  background-color: #F5F5F5;
  
}
.p-header {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 35px !important;
}
.p-textbody {
  margin-bottom: 5px !important;
}
.bg-cl-tr {
  background-color: rgba(255, 0, 0, 0.0); /* Red color with 50% transparency */
  color: #000 !important;
  border-color: rgba(255, 0, 0, 0.0);
}
.bg-input{
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: 38px !important;
  background-color: #F5F5F5 !important;
}

.ft-16 {
  font-size: 14px;
  font-weight: 500 !important;

}
/* Define styles for the active tab */
.p-text.active {
  font-weight: bold; /* You can adjust the styling as needed */
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out; /* Add a smooth transition effect */
}

/* Optional: Add hover effect for better user experience */
.p-text:hover {
  cursor: pointer;
  padding: 0px 0px 0px 0px;
  border-radius: 4px;
  color: #5F2F1B;
  font-size: 15px;
  font-weight: bold; /* You can adjust the styling as needed */
  transition: background-color 0.3s ease-in-out; /* Add a smooth transition effect */
  /* Add any additional styles for the hover effect */
}

.red-text{
  color: red !important;
  font-weight: 600 !important;
}

.card-image {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin: 5px 10px 0px 0px;
}

.card-address {
  width: "75%";
  height: 70px;
  margin-top: 5px;
  margin: 5px 10px 0px 0px;
}


  .rectangle {
  background-color: #f2e9d4 !important;
  border-radius: 10px;
  height: 30px;
  width: 100px;
  padding: 5px;
  }
  .p-texts {
    color: #000;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .product-image {
    height: 50%;
    width: 30%;
    
  }
  .product-item {
    /* background-color: red !important; */
    display: flex !important;
    flex-direction: row !important;    

  }
  .product-amount {
    width: 18%;

  }

   /* Cart List */
   .cart-list {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #F5F5F5 !important;
  
  }
  
  .cart-list h3 {
    margin-bottom: 15px;
    font-size: 1.5em;
  }
  
  .cart-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .total-price {
    margin-top: 10px;
    font-weight: bold;
  }
  .background-bt {
    background-color: #F5F5F5 !important;
  }

  .custom-owl-theme .owl-stage {
    transition: transform 0.5s ease; /* Adjust the duration and easing function as needed */
  }

  /* YourStyles.css */
.custom-left-arrow,
.custom-right-arrow {
  background-color: #ffffff; /* Set your desired background color */
  border: 1px solid #dddddd; /* Set your desired border style */
  border-radius: 5px; /* Set your desired border radius */
  padding: 5px; /* Set your desired padding */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-left-arrow:hover,
.custom-right-arrow:hover {
  background-color: #e0e0e0; /* Set your desired background color on hover */
}


.amount-label {
  font-size: xx-large !important;
  font-weight: 500 !important;
}

.broder-color{
  border-color: #5F2F1B !important;
}

.background-color{
  background-color: #5F2F1B !important;
}

.button {
  font-family: 'Poppins', sans-serif;
  background-color: #5F2F1B;
  color: #FFE5E5;
  padding: 10px 32px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  border: none;
}

.ant-form-item-required span {
  display: none;
}

.ant-form-item-required:after {
  display: none;
}

.green-text {
  color: #FFF !important;
  font-weight: 400 !important;
  background-color: #359650!important;
  font-family: 'Poppins', sans-serif;
  padding: 5px 10px 5px 10px ;
  border-radius: 6px;
  align-items: center !important;
  text-align: center;

}

.View-text {
  color: #FFF !important;
  font-weight: 400 !important;
  background-color: #359650!important;
  font-family: 'Poppins', sans-serif;
  padding: 5px 10px 5px 10px ;
  border-radius: 6px;
  align-items: center !important;
  text-align: center;
  height: 33px !important;
  top: -7px !important;

}
.blue-text {
  background-color: #045cff!important;
}
.yellow-text {
  background-color: #d7c719!important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead >tr>td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  width: 1px;
  height: 1.6em;
  background-color: #ffffff;
  transform: translateY(-50%);
  transition: background-color 0.2s;
  content: "";
}
.fs-6 {
  font-size: 13px !important;
}
.details {
  max-height: 20% !important;
}
.fw-normal {
  font-weight: 600 !important;
}

.fs-3{
  font-size: 19px !important;

}
.searchbox {
  border-radius: 30px !important;
  padding: 0px 10px 0px 25px ;
  width: 100%;
  /* background-color: #ffffff !important; */
}


.add-button {
  font-family: 'Poppins', sans-serif;
  background-color: #5F2F1B;
  color: #FFE5E5;
  padding: 10px 0px 10px 0px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  border: none;
}


:where(.css-dev-only-do-not-override-1rqnfsa).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid transparent!important;
  background-color: transparent;
}

.padding-top  {
  padding: 10px 20px 10px 20px;
}

/* Apply these styles to the loader container */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1); /* Adjust the background color and transparency as needed */
  z-index: 1000; /* Ensure the loader is on top of other elements */
}

/* Apply these styles to the loader background */
.loader-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Background overlay color and transparency */
  z-index: 999; /* Place it just below the loader */
}

/* Apply these styles to the loader itself */
.loader-center {
  text-align: center;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  background-color: var(--bs-secondary-bg-subtle) !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color:var(--bs-secondary-bg-subtle) !important;
}


:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead >tr>td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}



.header-image {
  background-color: #000 !important;
  border-radius: 50% !important;
  width: 10%;
  height: 10%;
  padding: 8px 8px 8px 8px ;
}

.black-color {
  background-color: #000 !important;
}